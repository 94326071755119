<template>
  <div class="d-flex justify-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="error"
      right
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>

    <v-icon
      v-if="!iconDisabled"
      color="error"
      size="32"
    >
      mdi-alert
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    iconDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: true,
    };
  },
};
</script>
